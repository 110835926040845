// import '../styles/styles.scss';// donne un preview du css en prod
import * as Sentry from '@sentry/react';
import domready from 'domready';

Sentry.init({
    dsn: 'https://21421ba01fa53d54defa2bf20c6659cb@o4507699322748928.ingest.us.sentry.io/4508880806477824',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/micromag\.studio/, /^https:\/\/micromag\.media/], // 'localhost'
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const needsPolyfill = () =>
    typeof window.Intl === 'undefined' ||
    typeof window.Intl.PluralRules === 'undefined' ||
    typeof window.IntersectionObserver === 'undefined' ||
    typeof window.ResizeObserver === 'undefined';

let micromag = null;
const boot = () => {
    const render = (Micromag) => {
        if (micromag === null) {
            const propsEl = document.getElementById('app-props');
            const props = propsEl !== null ? JSON.parse(propsEl.innerHTML) || {} : {};
            micromag = new Micromag(props);
        }
        const appEl = document.getElementById('app');
        micromag.render(appEl);
    };

    const onLoaded = ({ default: Micromag }) => {
        render(Micromag);
    };

    if (needsPolyfill()) {
        import(/* webpackChunkName: "micromag-polyfill" */ './micromag.polyfill').then(onLoaded);
    } else {
        import(/* webpackChunkName: "micromag" */ './micromag').then(onLoaded);
    }
};

const ready = (document.readyState || 'loading') !== 'loading';
if (ready) {
    boot();
} else {
    domready(boot);
}
